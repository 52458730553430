<template>
  <router-view />
</template>

<style>
@import url("https://use.typekit.net/bvk4qag.css");
html,
body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  width: 100vw;
  justify-content: center;
  justify-items: center;
  align-content: center;
  align-items: center;
  background-color: black;
}

#app {
  background-color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  align-content: center;
  align-items: center;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #ffffff;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: bebas-kai, sans-serif;
  font-weight: 400;
  font-style: normal;
}

h1 {
  font-size: 40px;
}
h2 {
  font-size: 35px;
}
h3 {
  font-size: 30px;
}
h4 {
  font-size: 25px;
}
h5 {
  font-size: 20px;
}

p,
li {
  font-family: acumin-pro, sans-serif;

  font-weight: 400;

  font-style: normal;
}
</style>
